.large-image {
    display: block;
}

.small-image {
    display: none;
}

@media (max-width: 600px) {
    .large-image {
        display: none;
    }

    .small-image {
        display: block;
    }

    .text {
        font-size: 12px;  /* taille de la police pour les petits écrans */
    }
}

.text {
    font-size: 25px;  /* taille de la police pour les petits écrans */
}

@media (max-width: 1200px) {
    .text {
        font-size: 14px;  /* taille de la police pour les écrans de taille moyenne */
    }
}

.slick-dots.custom-indicator li button:before {
    color: white;  /* couleur des indicateurs */
    font-size: 20px;  /* taille des indicateurs */
    opacity: 1 !important;  /* force l'opacité à 1 */
}
