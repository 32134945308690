.custom-hr {
    border: none;
    color: var(--gold-color);
    background-color: var(--gold-color);
    height: 1px;
    margin: 5vh;
}

.react-pdf__Page {
    margin-bottom: 0;
}

.spinner {
    border: 10px solid var(--light-color);
    border-radius: 50%;
    border-top: 10px solid var(--gold-color);
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
