/* Navbar.css */

:root {
    --gold-color: #DDB35C;
    --dark-color: #2F313B;
    --medium-color: #9B9283;
    --light-color: #f9f4f1;
    --red-color: #E73F2E;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    background-color: var(--dark-color);
    padding: 10px 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    height: 10vh;
}

.logo {
    width: 8vh;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    padding-left: 2px;
}

.logo img {
    width: 40px;
    height: 40px;
}

.burger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;
    position: relative;
    padding-right: 10px;
}/* Ajout du padding à droite */


.burger div {
    width: 100%;
    height: 2px;
    background-color: var(--light-color);
    transition: all 0.3s linear;
}

.burger::before,
.burger::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--light-color);
    transition: all 0.3s linear;
}

.burger::before {
    top: -6px;
    content: none;
}

.burger::after {
    bottom: -6px;
    content: none;
}

.navbar-list {
    display: flex;
    list-style: none;
    padding: 0 0px;
    transition: transform 0.3s ease-out;
}

.navbar-item {
    color: var(--light-color);
    padding: 15px;
}

.navbar-link {
    color: var(--gold-color);
    text-decoration: none;
    padding: 20px;
    border-radius: 5px;
}

.navbar-link:hover {
    color: #ddd;
    border-color: #ddd;
}

/* Styles pour les petits écrans */
@media (max-width: 600px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        order: 1;
    }

    .burger {
        display: flex;
        order: 3;
    }

    .navbar-list {
        position: fixed;
        top: 0;
        left: 0; /* Utilisation de left: 0 pour positionner le menu à gauche */
        width: 55%; /* Modification de la largeur pour occuper tout l'écran */
        max-height: 100vh;
        overflow: auto;
        background-color: var(--light-color);
        transform: translateX(-100%); /* Utilisation de translateX(-100%) pour cacher le menu à gauche */
        z-index: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
        margin: 0;
        padding-top: 50px;
    }

    .navbar-list.open {
        transform: translateX(0); /* Utilisation de translateX(0) pour afficher le menu */
        padding-top: 10px;
    }

    .navbar-item {
        display: block;
    }

    .navbar-link {
        color: var(--gold-color);
    }

    .navbar-link:hover {
        color: #ddd;
        border-color: #ddd;
    }
}

.logo-container {
    display: flex;
    align-items: center;
}

.music-button {
    margin-left: 10px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.music-button:hover {
    background-color: #f0f0f0;
}

